// src/App.js
import React, { Component } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import routes from "./routes"; // Asegúrate de que este importe es correcto y apunta al archivo donde defines tus rutas

// Import styles
import "./assets/css/pe-icon-7.css";
import "./assets/scss/themes.scss";

class App extends Component {
  render() {
    return (
      <Router>
        <Routes>
          {routes.map((route, idx) => (
            <Route path={route.path} element={route.component} key={idx} />
          ))}
        </Routes>
      </Router>
    );
  }
}

export default App;
