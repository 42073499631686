import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const Root = styled(Box)(({ theme }) => ({
  maxWidth: '800px',
  margin: 'auto',
  padding: theme.spacing(2),
  textAlign: 'center',
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: '28px',
  fontWeight: 'bold',
  marginBottom: theme.spacing(3),
  color: '#17335e',
  borderBottom: '1px solid #ccc',
  paddingBottom: theme.spacing(1),
}));

const ContentTypography = styled(Typography)(({ theme }) => ({
  textAlign: 'justify',
}));

export default function CustomizedAccordions({ data }) {
  const [expanded, setExpanded] = React.useState(null);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Root>
      <Title>Preguntas Frecuentes</Title>
      {data.map(({ id, title, content }) => (
        <Accordion expanded={expanded === id} onChange={handleChange(id)} key={id}>
          <AccordionSummary aria-controls={`${id}-content`} id={`${id}-header`}>
            <Typography>{title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ContentTypography component="div">
              {id === 3 ? (
                <Box component="div">
                  <Typography>Perenterol cuenta con dos presentaciones:</Typography>
                  <Box component="ul" sx={{ pl: 2 }}>
                    <li>Cápsulas de 250mg.</li>
                    <li>Sobres en polvo para suspensión oral de 250mg sabor tutifruti. Esta presentación es más conveniente para niños o para personas con dificultad para tragar cápsulas.</li>
                  </Box>
                </Box>
              ) : (
                <div dangerouslySetInnerHTML={{ __html: content }} />
              )}
            </ContentTypography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Root>
  );
}
