import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardMedia, CardContent, Typography, Box, Grid, Button } from '@mui/material';

export const ArticleList = ({ articles }) => {
  const navigate = useNavigate();

  const handleMoreInfoClick = (article) => {
    navigate(`/article/${article.slug}`); // Navegación usando el slug predefinido
  };

  return (
    <Box sx={{ padding: 3, display: 'flex', justifyContent: 'center' }}>
      <Grid container spacing={3} sx={{ maxWidth: 1200 }}>
        {articles.map(article => (
          <Grid item xs={12} sm={6} md={4} key={article.id} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Card
              sx={{
                maxWidth: 345,
                boxShadow: 3,
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                fontFamily: 'Arial, sans-serif',
                borderRadius: 2,
                '&:hover': {
                  boxShadow: 6,
                },
              }}
            >
              {
                article.image && (
                  <CardMedia
                    component="img"
                    height="180"
                    image={article.image}
                    alt={article.title}
                  />
                )
              }
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography gutterBottom variant="h5" component="div" sx={{ color: '#1d3e69', fontWeight: 'bold', fontFamily: 'Arial, sans-serif' }}>
                  {article.title}
                </Typography>
                <Typography variant="body2" component="p" sx={{ color: '#6c757d', fontFamily: 'Arial, sans-serif' }}>
                  {article.description}
                </Typography>
                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between', color: '#1f266a' }}>
                  <Typography variant="body2" component="span" color="text.secondary" sx={{ fontFamily: 'Arial, sans-serif' }}>
                    {article.date}
                  </Typography>
                  <Typography variant="body2" component="span" color="text.secondary" sx={{ fontFamily: 'Arial, sans-serif' }}>
                    {article.ReadingTime}
                  </Typography>
                </Box>
              </CardContent>
              <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: '#d14f9d',
                    color: 'white',
                    '&:hover': { backgroundColor: '#a83c7f' }
                  }}
                  onClick={() => handleMoreInfoClick(article)}
                >
                  Más información
                </Button>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ArticleList;
