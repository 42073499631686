const data1 = [
	{
	  id: 1,
	  title: '¿Qué es Perenterol y para qué se utiliza?',
	  content: 'Perenterol es un medicamento que contiene Saccharomyces boulardii CNCM I-745, una cepa probiótica de levadura no patógena (es decir, que no origina o desarrolla enfermedades). Se utiliza principalmente para tratar todo tipo de diarreas, ya que restaura y mantiene en equilibrio la flora intestinal (microbiota intestinal).',
	},
	{
	  id: 2,
	  title: '¿Cómo actúa Perenterol en el cuerpo para tratar la diarrea?',
	  content: 'Al tomar Perenterol, se incrementa la población de bacterias benéficas en el intestino. Estas compiten con los microorganismos dañinos, lo que resulta útil para contrarrestar los desequilibrios causados por factores como infecciones, cambios de dieta, uso de antibióticos u otros factores que desestabilicen la flora intestinal.',
	},
	{
	  id: 3,
	  title: '¿En qué presentaciones encuentro Perenterol?',
	  content: 'Perenterol cuenta con dos presentaciones: Cápsulas de 250 mg y sobres en polvo para suspensión oral de 250 mg sabor tutifruti. Esta última presentación es más conveniente para niños o para personas con dificultad para tragar cápsulas.',
	},
	{
	  id: 4,
	  title: '¿Cuál es la dosis recomendada de Perenterol para niños?',
	  content: 'La dosis para niños de 12 años en adelante es de 250 mg cada 12 horas (mañana y noche). Para niños de 3 meses en adelante, la dosis es de 250 mg cada 24 horas.',
	},
	{
	  id: 5,
	  title: '¿Cuál es la dosis recomendada de Perenterol para adultos?',
	  content: 'La dosis usual en adultos es de 250 mg cada 12 horas (mañana y noche).',
	},
	{
	  id: 6,
	  title: '¿Puedo tomar Perenterol si estoy tomando otro medicamento?',
	  content: 'Aunque el ingrediente activo de Perenterol, Saccharomyces Boulardii CNCM I-745, se considera seguro y no suele interactuar con otros medicamentos de forma significativa, es posible que existan algunas excepciones. Siempre es recomendable consultar con tu médico, incluso cuando los medicamentos sean de venta libre. Por la naturaleza del principio activo, se recomienda no administrar simultáneamente con antifúngicos. Si la diarrea es grave o persistente, el tratamiento de Perenterol puede continuar de acuerdo a las indicaciones de su médico.',
	},
	{
	  id: 7,
	  title: '¿Puedo tomar Perenterol mientras estoy tomando antibióticos?',
	  content: 'Sí, juntos es mejor. Perenterol a menudo se prescribe junto con antibióticos para ayudar a prevenir la diarrea asociada con su uso, ya que ayuda a mantener el equilibrio de la flora intestinal durante el tratamiento.',
	},
	{
	  id: 8,
	  title: '¿Dónde puedo comprar Perenterol?',
	  content: 'Encuentra Perenterol en todas las cadenas de farmacia del país.',
	},
	{
	  id: 9,
	  title: '¿Perenterol es un producto de origen natural?',
	  content: 'Sí, su principal activo es Saccharomyces Boulardii, una cepa probiótica no patógena que se encuentra en la piel de algunas frutas tropicales. Por esa razón, se considera que Perenterol es un producto de origen natural.',
	},
	{
	  id: 10,
	  title: '¿A partir de qué edad se puede tomar Perenterol?',
	  content: 'Perenterol se puede tomar desde bebés, incluso a partir de los 3 meses de edad.',
	},
  ];
  
  export default data1;
  