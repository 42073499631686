import React, { Suspense, useState, useEffect } from "react";
import { ArticleList } from "../../component/Blog/ArticleList";
import ButtonListBlog from "../../component/Blog/ButtonListBlog";
import DolorDiarreaEstomago from "../../assets/images/blog/DolorDiarreaEstomago.webp";
import data from "../../component/Blog/Data/data";
import data1 from "../../component/data1";
import CustomizedAccordions from "../../component/Faq";

const NavbarPage = React.lazy(() => import("../../component/Navbar/NavBar"));
const Section = React.lazy(() => import("./Section"));
const About = React.lazy(() => import("../../component/About"));
const Services = React.lazy(() => import("../../component/Services"));
const Feature = React.lazy(() => import("../../component/Feature"));
const Project = React.lazy(() => import("../../component/Project"));
const Footer = React.lazy(() => import("../../component/Footer/Footer"));
const News = React.lazy(() => import("../../component/News"));
const Statistics = React.lazy(() => import("../../component/Statistics"));

function Layout1(props) {
  const [navItems] = useState([
    { id: 1, idnm: "inicio", navheading: "Inicio" },
    { id: 2, idnm: "about", navheading: "¿Qué es Perenterol?" },
    { id: 4, idnm: "features", navheading: "Microbiota Intestinal" },
    { id: 5, idnm: "ButtonListBlog", navheading: "Blog" },
    { id: 6, idnm: "project", navheading: "Contenido para Médicos" },
  ]);

  const [pos, setPos] = useState(document.documentElement.scrollTop);
  const [imglight, setImglight] = useState(false);
  const [navClass, setNavClass] = useState("");
  const [headerImage, setHeaderImage] = useState(DolorDiarreaEstomago);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const scrollNavigation = () => {
      var scrollup = document.documentElement.scrollTop;
      if (scrollup > pos) {
        setNavClass("nav-sticky");
        setImglight(false);
      } else {
        setNavClass("");
        setImglight(false);
      }
      setPos(scrollup);
    };

    window.addEventListener("scroll", scrollNavigation);
    return () => {
      window.removeEventListener("scroll", scrollNavigation);
    };
  }, [pos]);

  const PreLoader = () => {
    return (
      <div id="preloader">
        <div id="status">
          <div className="spinner">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
        </div>
      </div>
    );
  };

  const allCategories = [
    "Todo",
    ...new Set(data.map((article) => article.category)),
  ];
  const [categories] = useState(allCategories);
  const [articles, setArticles] = useState(data);
  const [articles1, setArticles1] = useState(data1);

  const filterCategory = (category) => {
    if (category === "Todo") {
      setArticles(data);
      setHeaderImage(DolorDiarreaEstomago);
    } else {
      const filteredData = data.filter(
        (article) => article.category === category
      );
      setArticles(filteredData);
      if (filteredData.length > 0) {
        setHeaderImage(filteredData[0].image);
      }
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);

    if (event.target.value === "") {
      setArticles(data);
    } else {
      const filteredData = data.filter(
        (article) =>
          article.title
            .toLowerCase()
            .includes(event.target.value.toLowerCase()) ||
          article.description
            .toLowerCase()
            .includes(event.target.value.toLowerCase())
      );
      setArticles(filteredData);
    }
  };

  return (
    <React.Fragment>
      <Suspense fallback={PreLoader()}>
        <NavbarPage
          navItems={navItems}
          navClass={navClass}
          imglight={imglight}
        />

        <Section id="inicio" />
        <About id="about" />
        <Services id="services" />
        <Statistics />
        <Feature id="features" />

        <div
          style={{
            maxWidth: "800px",
            margin: "0 auto",
            textAlign: "center",
            paddingTop: "20px",
          }}
          id="ButtonListBlog"
        >
          <h2 style={{ color: "#17335e" }}>Artículos</h2>
          <div
            style={{
              maxWidth: "800px",
              margin: "0 auto",
              padding: "20px 0",
              textAlign: "center",
            }}
          >
            <input
              type="text"
              placeholder="Buscar artículos..."
              value={searchTerm}
              onChange={handleSearch}
              style={{
                width: "100%",
                padding: "10px",
                fontSize: "16px",
                borderRadius: "5px",
                border: "1px solid #ccc",
              }}
            />
          </div>
        </div>

        <ButtonListBlog
          categories={categories}
          filterCategory={filterCategory}
        />
        <ArticleList articles={articles} />
        <Project id="project" />
        <News id="news" />
        <CustomizedAccordions data={articles1} />
        <Footer />
      </Suspense>
    </React.Fragment>
  );
}

export default Layout1;
