import React from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';
import data from '../Data/data';
import { Box, Typography, Container, Button } from '@mui/material';
import NavbarPage from '../../Navbar/NavBar';
import Footer from '../../Footer/Footer';

const ArticlePage = () => {
  const { slug } = useParams(); // Usamos 'slug' en lugar de 'id'
  const article = data.find(article => article.slug === slug); // Encuentra el artículo por slug

  const navItems = [
    { id: 1, idnm: "inicio", navheading: "inicio" },
    { id: 2, idnm: "about", navheading: "¿Qué es Perenterol?" },
    { id: 4, idnm: "features", navheading: "Microbiota Intestinal" },
    { id: 5, idnm: "ButtonListBlog", navheading: "Blog" },
    { id: 6, idnm: "project", navheading: "Contenido para Médicos" },
  ];

  if (!article) {
    return <Typography variant="h6" align="center" sx={{ marginTop: 5 }}>Artículo no encontrado</Typography>;
  }

  return (
    <React.Fragment>
      <NavbarPage navItems={navItems} navClass="" imglight={false} />
      <Container maxWidth="md" sx={{ padding: 3 }}>
        <Typography variant="h4" align="center" gutterBottom sx={{ fontWeight: 'bold', color: '#192a56', marginBottom: 4 }}>
          {article.title}
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 3 }}>
          <img src={article.image} alt={article.title} style={{ width: '100%', height: 'auto', maxWidth: '600px' }} />
        </Box>
        <Box component="div" sx={{ lineHeight: 1.6 }}>
          <div dangerouslySetInnerHTML={{ __html: article.content }} />
        </Box>
        <Button 
          variant="contained" 
          color="primary" 
          component={RouterLink}
          to="/#ButtonListBlog"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#192a56',
            color: 'white',
            fontWeight: 'bold',
            textTransform: 'none',
            padding: '10px 20px',
            maxWidth: '200px',
            margin: '20px auto',
            '&:hover': {
              backgroundColor: '#273c75',
            },
            '& .MuiButton-startIcon': {
              marginRight: '8px',
            },
          }}
          startIcon={<span>&#8592;</span>}
        >
          Volver
        </Button>
      </Container>
      <Footer />
    </React.Fragment>
  );
};

export default ArticlePage;
