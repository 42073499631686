// NavBar.js
import React, { useState, useEffect } from "react";
import {
  Navbar,
  Nav,
  NavbarToggler,
  NavItem,
  NavLink,
  Container,
  Collapse,
} from "reactstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logodark from "../../assets/images/BannerPerenterol.png";
import logolight from "../../assets/images/BannerPerenterolBlanco.png";
import FeatherIcon from "feather-icons-react";

export const useHandleNavClick = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleNavClick = (id) => {
    if (location.pathname === "/") {
      const element = document.querySelector(`#${id}`);
      if (element) {
        const navbarHeight = document.querySelector("#navbar").offsetHeight;
        const offset = navbarHeight + 10; // Altura del navbar + margen adicional
        const additionalMargin = 1; // Margen adicional para ajustar la posición
        const elementPosition =
          element.getBoundingClientRect().top + window.pageYOffset;
        const offsetPosition = elementPosition - offset - additionalMargin;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    } else {
      navigate(`/#${id}`);
    }
  };

  return handleNavClick;
};

const NavbarPage = (props) => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const location = useLocation();
  const handleNavClick = useHandleNavClick();

  const toggle = () => {
    setIsOpenMenu(!isOpenMenu);
  };

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash;
      if (hash) {
        const element = document.querySelector(hash);
        if (element) {
          const navbarHeight = document.querySelector("#navbar").offsetHeight;
          const offset = navbarHeight + 100;
          const additionalMargin = 50; // Margen adicional para ajustar la posición
          const elementPosition =
            element.getBoundingClientRect().top + window.pageYOffset;
          const offsetPosition = elementPosition - offset - additionalMargin;

          window.scrollTo({
            top: offsetPosition,
            behavior: "smooth",
          });
        }
      }
    };

    window.addEventListener("hashchange", handleHashChange, false);
    handleHashChange();

    return () => {
      window.removeEventListener("hashchange", handleHashChange, false);
    };
  }, [location]);

  return (
    <React.Fragment>
      <Navbar
        expand="lg"
        fixed="top"
        className="navbar-custom sticky sticky-dark align-items-center navbar-white-bg"
        id="navbar"
      >
        <Container className="d-flex justify-content-between align-items-center">
          <Link className="logo" to="/">
            {props.imglight === true ? (
              <img src={logolight} alt="" height="30" />
            ) : (
              <img src={logodark} alt="" height="45" />
            )}
          </Link>
          <NavbarToggler onClick={toggle}>
            <FeatherIcon icon="menu" />
          </NavbarToggler>
          <Collapse
            id="navbarCollapse"
            isOpen={isOpenMenu}
            className="navbar-collapse"
          >
            <Nav className="ml-auto" id="navbar-navlist">
              {props.navItems.map((item, key) => (
                <NavItem
                  key={key}
                  className={item.navheading === "Home" ? "active" : ""}
                >
                  <NavLink
                    to={`/#${item.idnm}`}
                    className={`nav-link ${
                      item.navheading === "Home" ? "active" : ""
                    }`}
                    onClick={(e) => {
                      e.preventDefault();
                      handleNavClick(item.idnm);
                      // Añadir una clase para mostrar un cambio visual al hacer clic
                      const links = document.querySelectorAll(
                        "#navbar-navlist .nav-link"
                      );
                      links.forEach((link) => link.classList.remove("clicked"));
                      e.currentTarget.classList.add("clicked");
                    }}
                    style={{ cursor: "pointer" }} // Asegurarse de que el cursor sea de tipo pointer
                  >
                    {item.navheading}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
            <ul className="list-inline ml-auto menu-social-icon mb-0 py-2 py-lg-0">
              <li className="list-inline-item ml-0">
                <a
                  href="https://www.facebook.com/perenterolpy/?locale=es_LA"
                  className="menu-social-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FeatherIcon icon="facebook" className="icon-xs sw_1-5" />
                </a>
              </li>
              <li className="list-inline-item">
                <a
                  href="https://www.instagram.com/perenterol_gt/"
                  className="menu-social-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FeatherIcon icon="instagram" className="icon-xs sw_1-5" />
                </a>
              </li>
            </ul>
          </Collapse>
        </Container>
      </Navbar>
    </React.Fragment>
  );
};

export default NavbarPage;
