import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import FooterLink from "../Footer/Footer_link";

// Footer Image
import footer_image from "../../assets/images/hero-1-bg-img.png";

import logolight from "../../assets/images/BannerPerenterolBlanco.png";
// Import Logo

class Footer extends Component {
  constructor(props){
    super(props);
    this.state = {
      links:[
        {
          id: 1,
          title: "Contáctanos",
          child: [
            { title: "Instagram", link: "https://www.instagram.com/perenterol_gt/" },
            { title: "Facebook", link: "https://www.facebook.com/perenterolpy/?locale=es_LA" },
            // { title: "WhatsApp", link: "https://wa.me/50240319499?text=Hola%2C%20me%20gustar%C3%ADa%20saber%20m%C3%A1s%20sobre%20Perenterol." },
          ],
        },
        {
          id: 2,
          title: "Productos",
          child: [
            { title: "Sobres", link: "#services" }, // Cambiado el enlace a #
            { title: "Cápsulas", link: "#services" }, // Cambiado el enlace a #
          ]
        },
      ],
    };
  }

  scrollToElement = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  render() {
    return (
      <React.Fragment>
        {/* Footer Start */}
        <footer
          className="footer"
          style={{ backgroundImage: "url(" + footer_image + ")" }}
        >
          <Container>
            <Row>
              <Col lg={4}>
                <div className="mb-5">
                  <Link to="#" onClick={() => this.scrollToElement('inicio')}>
                    <img src={logolight} alt="" className="" height="28" />
                  </Link>
                  <p className="text-white-50 my-4">Levadura probiótica que protege y restaura la flora intestinal.</p>
                  {/* <Link to="#" className="text-white-70"><FeatherIcon className="icon mr-1" icon="instagram" />{" "} Join Us In Instagram</Link> */}
                </div>
              </Col>
              <Col lg={7} className="offset-lg-1">
                <Row>
                  {/* Render Footer Link */}
                  {this.state.links.map((item, key) => (
                    <Col md={4} key={key}>  
                    <h4 className="text-white f-22 fw-normal mb-3">
                          {item.title}
                        </h4>
                        <ul className="list-unstyled footer-sub-menu">
                          {item.child.map((linkItem, key) => (
                            <li key={key}>
                              <Link
                                className="footer-link"
                                to={linkItem.link}
                                onClick={() => this.scrollToElement('services')}
                              >
                                {linkItem.title}
                              </Link>
                            </li>
                          ))}
                        </ul>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
            {/* Render Footer Link End */}
            <FooterLink />
          </Container>
        </footer>
        {/* Footer End */}
      </React.Fragment>
    );
  }
}

export default Footer;
