import React from 'react';
import { Box, Button, Grid } from '@mui/material';

const ButtonListBlog = ({ categories, filterCategory }) => {
    return (
        <Box sx={{ padding: 3, display: 'flex', justifyContent: 'center' }}>
            <Grid container spacing={2} sx={{ maxWidth: 1200, justifyContent: 'center' }}>
                {categories.map((category, index) => (
                    <Grid item key={index}>
                        <Button
                            variant="contained"
                            sx={{ 
                                backgroundColor: '#f1f3fd', 
                                color: '#343a40', 
                                '&:hover': { backgroundColor: '#ffffff' },
                                fontFamily: 'Arial, sans-serif',
                                fontWeight: 'bold'
                            }}
                            onClick={() => filterCategory(category)}
                        >
                            {category}
                        </Button>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default ButtonListBlog;
